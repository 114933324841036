import {html, css, LitElement, unsafeCSS} from 'lit'
import { classMap, styleMap } from 'lit/directives/class-map.js'
import {TBase} from '../base'


export class TAnchor extends TBase 
{
    static styles = [ 
        TBase.styles, 
        css`
            .active { font-weight: bold }
        ` ]

    static properties = {
        ...TBase.properties,
        href : {type: String},
        active:{type: Boolean},
        color: {type: String, attribute: "data-vx-color"}, 
    };

    constructor() {
        super()
        this.color = "dark"
    }

    render() {
        let classes = ['link-offset-2 link-offset-3-hover link-underline link-underline-opacity-0 link-underline-opacity-75-hover']
        if(this.color)  classes.push(`link-${this.color}`)
        if(this.active) classes.push('active')
        return html`
            ${super.render()}
            <a href="${this.href}" class="${classes.join(' ')}" @click="${this._navigate}"><slot></slot></a>
        `
    }

    // --- custom functions 
    _navigate(e) {
        e.preventDefault()
        this.dispatchEvent(new CustomEvent('navigate', {bubbles: true, cancelable: true, detail: {url: this.href}}))
    }
}

if (!customElements.get('vx-anchor')) 
    customElements.define('vx-anchor', TAnchor);    
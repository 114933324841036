import page from 'page'
const EventEmitter = require('events')
// import {showAlertToast} from './../components/popups/toast'

export const routes = [
    {uri: '/'                    , view: 'dashboard'    , login : true },
    {uri: '/dashboard'           , view: 'dashboard'    , login : true },
    {uri: '/login'               , view: 'login'        , login : false},
    {uri: '/vsqv'                , view: 'vsqv'         , login : true },
    {uri: '/vsqv/dids'           , view: 'dids'         , login : true },
    {uri: '/vsqv/dids/:uid'      , view: 'did'          , login : true },
    {uri: '/vsqv/templates'      , view: 'templates'    , login : true },
    {uri: '/vsqv/templates/:uid' , view: 'template'     , login : true },
    {uri: '/vsqv/campaigns'      , view: 'campaigns'    , login : true },
    {uri: '/vsqv/campaigns/:uid' , view: 'campaign'     , login : true },
    {uri: '/users'               , view: 'users'        , login : true },
    {uri: '/users/:uid'          , view: 'user'         , login : true },
    {uri: '/sbc'                 , view: null           , login : true },
    {uri: '/svi'                 , view: null           , login : true },
    {uri: '/bot'                 , view: null           , login : true },
    {uri: '/rec'                 , view: null           , login : true },
    {uri: '/rtc'                 , view: null           , login : true },
    {uri: '/trunk'               , view: null           , login : true },
    {uri: '/queue'               , view: null           , login : true },
    {uri: '/logout'              , view: 'login'        , logout: true }
]

export class TRouter extends EventEmitter
{
    constructor() {
        super()
    }

    initialize() {
        var self = this
        routes.forEach( route => { 
            if(route.view)  { 
                console.log(route.uri, '-->', route.view); 
                page(route.uri, (ctx) => self.emit('route', route, ctx)) 
            } 
        })
        page('*', (ctx) => { console.log('page not found', ctx); page.redirect('/') })
        document.body.addEventListener('login'      , e => self.processEvent('login' , e))
        document.body.addEventListener('submit'     , e => self.processEvent('submit', e))
        document.body.addEventListener('action'     , e => self.processEvent('action', e))
        document.body.addEventListener('navigate'   , e => self.processEvent('navigate', e))
        document.body.addEventListener('itemclick'  , e => self.processEvent('itemclick', e))
        console.log('router initialized')
    }

    start() {
        page()
    }

    processEvent(type, event) {
        console.log('ROUTER', 'processEvent :', type, event)
        this.emit(type, event.detail)
    }

    goto(uri) {
        console.log('ROUTER', 'goto', uri)
        page(uri)
        //loadView(view, ctx)
    }

    notSubscribed(module) {
        console.log(module, 'not available')
        showAlertToast(`vous n'avez aucun ${module} dans votre infrastructure.`, 'danger')
        return true
    }

    routeFor(url) {
        return routes.find( route => route.uri.length>1 && url.startsWith(route.uri))
    }

    navigate(url) {
        console.log('navigating to', url)
    }

    _navigate(url)
    {
        console.log('ROUTER', 'navigate', url)
        let urlData = new URL(url.toLowerCase())
        var handled = (urlData.hostname === window.location.hostname)
        if(handled)
        {
            let selRoute = null
            let uri = urlData.pathname || '/'
            for(let route of routes.filter( route => route.noSub ))
            {
                if(uri === route.uri)
                {
                    this.notSubscribed(route.noSub)
                    selRoute = route
                    break 
                }
            }
            console.log(`page(${uri})`)
            if(!selRoute)    page(uri)
            console.log('handled', uri)
            
        }
    console.log(url, "handled ?", handled, urlData.pathname)
        return handled
    }
}

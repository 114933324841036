import {html, css} from 'lit'
import {TBase} from '../base'


export class TTable extends TBase 
{
    static styles = [ 
        TBase.styles, 
        css`
    ` ]

    static properties = {
        ...TBase.properties,
        name        : {type: String},
        data        : {type: Object, attribute: 'data-vx-data'},
        cols        : {type: Array , attribute: 'data-vx-cols'},
        rows        : {type: Array , attribute: 'data-vx-rows'},
        color       : {type: String, attribute: "data-vx-color"},
        action      : {type: String, attribute: "data-vx-row-select-action"},
        small       : {type: Boolean, attribute: "small"}, 
        hover       : {type: Boolean, attribute: "hover"}, 
        striped     : {type: Boolean, attribute: "striped"}, 
        bordered    : {type: Boolean, attribute: "bordered"},
        responsive  : {type: Boolean, attribute: "responsive"}  
    }

    constructor() {
        super()
        this.data = []
        this.src  = null
        this.cols = []
        this.rows = []
        this.action = 'edit'
    }

    _compile() {
        if(data?.length) {
            let cols = new Set()
            let rows = []
    
            data.forEach( item => {
                Object.keys(item).forEach(cols.add, cols)
                rows.push(Object.values(item))
            } )
            this.cols = this.cols.length? this.cols : Array.from(cols)
            this.rows = {...this.rows, ...rows}                
        }
    }

    compile() {
        console.log('table compile', this.data)
        var self = this
        this.cols = (this.cols?.length) ? this.cols : []
        this.rows = (this.data) ? [] : this.rows
        const render = (window[this.render] && typeof window[this.render] === "function") ? window[this.render] : (key, val) => val

        const addRow = (item) => {
            var row = {data: item, cells: []}
            for(let col of this.cols) {
                if(col.data) {
                    var key = col.data
                    var val = (key === '__all__')? item : item[key]
                    row.cells.push(render(key, val))
                }
                else row.cells.push('')
            }
            self.rows.push(row)
        }
    
        const setCols = (data) => {
            if(!self.cols?.length) {
                let cols = new Set()
                data.forEach( item => Object.keys(item).forEach(cols.add, cols) )
                self.cols = Array.from(cols).map( col => { return {data:col, title:col} })
            }
            else {
                self.cols = self.cols.map( col => { 
                    if(typeof col === 'string')   col = {data:col, title:col}
                    col.title = col.title || col.data
                    return col 
                })
            }
        }
    
        const setRows = (data) => {
            if(!self.rows?.length) 
                data.forEach( item => addRow(item) )
        }

        try {
            if(this.data) {
                setCols(this.data)
                setRows(this.data)
            }
            console.log('compiled data', this.data)
            console.log('compiled cols', this.cols)
            console.log('compiled rows', this.rows)
        }
        catch(e) { console.error('error', e) }
    }

    render() {
        this.compile()
        console.log('rendering', this.rows)
        let classes = ['table']
        if(this.color)      classes.push(`table-${this.color}`)
        if(this.striped)    classes.push('table-striped')
        if(this.small)      classes.push('table-sm')
        if(this.hover)      classes.push('table-hover')
        if(this.bordered)   classes.push('table-bordered')
        if(this.responsive) classes.push('table-responsive')

        return html`
            ${super.render()}
            <table class="${classes.join(' ')}">
                <thead>
                    <tr>
                        ${this.cols.map( col => html`<th scope="col">${col.title}</th>` ) }    
                    </tr>
                </thead>
                <tbody>
                    ${this.rows.map( row => 
                        html`<tr @click="${this._rowClick}" data-row=${JSON.stringify(row.data)}>
                                ${row.cells.map( cell => html`<td>${cell}` )}</tr>`
                    )}
                </tobyd>
            </table>
        `
    }

        // --- custom functions 
        _rowClick(e) {
            e.preventDefault()
            const trEl = e.target.closest('tr')
            const data = {sender: this, id: this.name, action: this.action, item: JSON.parse(trEl.getAttribute('data-row') || '{}') }
            this.dispatchEvent(new CustomEvent('action', {bubbles: true, cancelable: true, detail: data}))
            console.log('clicked', data)
        }    
}

if (!customElements.get('vx-table')) 
    customElements.define('vx-table', TTable)


/*
let cols = ["name", "age"]
let data = [{name: 'paul', age: 32}, {name: 'jack', age: 40}]
console.log(JSON.stringify(cols))
console.log(JSON.stringify(data))
*/
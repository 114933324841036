import 'bootstrap';
import "./wcl/icons/bs-icons"
import "./wcl/layout"
import "./wcl/anchors/anchor"
import "./wcl/anchors/menuitem"
import "./wcl/forms/form"
import "./wcl/forms/login"
import "./wcl/card/card"
import "./wcl/card/imgcard"
import "./wcl/card/valuecard"
import "./wcl/buttons/button"
import "./wcl/tables/table"
import "./wcl/tables/datatable"
import "./wcl/charts/chart"
import "./wcl/sidebars/basic"
import "./wa"

function onDocumentReady(fn) {
    if (document.readyState !== 'loading')  fn(document.readyState)
    else document.addEventListener('DOMContentLoaded', fn);
}
  
window.onload = () =>
{
    onDocumentReady(function (i) 
    {
        // ...onDocumentReady

        // import {showAlertToast, showConfirmToast} from './wcl/popups/toast'
        // import {showModal, ShowPromptModal} from './wcl/popups/modal'
        // showAlertToast('ceci est un message de warning..')
        // showConfirmToast('ceci est une demande de confirmation')
        // showModal('this is a modal', "this is a title")
        // ShowPromptModal('this is a prompt')
    })
}

import {html, css} from 'lit'
import { classMap } from 'lit/directives/class-map'
import {TBase} from '../base'

export class TValueCard extends TBase
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properties,
        title : {type: String, attribute: 'data-vx-title'},
        header: {type: String, attribute: 'data-vx-header'},
        footer: {type: String, attribute: 'data-vx-footer'},
        icon  : {type: String, attribute: 'data-vx-icon'},
        value : {type: String, attribute: 'data-vx-value'},
        href  : {type: String, attribute: 'href'},
        shadow: {type: Boolean, attribute: 'shadow'},
    };

    constructor() {
        super();
        this.imgpos = this.imgpos || 'top'
    }

    render() {
        let htmlContent = '';
        if(!this.image)     this.imgpos = 'top'
        var classes = {card: true, 'text-center': true, 'pt-3':true, shadow: this.shadow}

        return html`
            ${super.render()}
            <div class="${classMap(classes)}">
                ${this.header? html`<div class="card-header">${this.header}</div>` : ''}
                <vx-icon name=${this.icon} size=48></vx-icon>
                <div class="card-body">
                    ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                    <h1>${this.value}</h1>
                    <div class="card-text"><slot/></div>
                </div>
                ${this.footer || this.href ? html`
                <div class="card-footer d-flex flex-row justify-content-between bg-transparent">
                    <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                    ${(this.href)? html`<span class="ps-2"><a href="${this.href}" @click="${this._navigate}"><i class="icon-three-dots"></i></a></span>`: html``}
                </div>` : html``}
            </div>`
    }

    // --- custom functions 
    _navigate(e) {
        console.log('value card navigate', this.href, e)
        e.preventDefault()
        this.dispatchEvent(new CustomEvent('navigate', {bubbles: true, cancelable: true, detail: {url: this.href}}))
    }
}

if (!customElements.get('vx-valuecard')) 
    customElements.define('vx-valuecard', TValueCard);
import {html, css} from 'lit'
import { classMap } from 'lit/directives/class-map'
import {TBase} from '../base'

export class TImageCard extends TBase
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properties,
        title : {type: String, attribute: 'data-vx-title'},
        header: {type: String, attribute: 'data-vx-header'},
        footer: {type: String, attribute: 'data-vx-footer'},
        image : {type: String, attribute: 'data-vx-image'},
        imgpos: {type: String, attribute: 'data-vx-imgpos'},
        href  : {type: String, attribute: 'href'},
        shadow: {type: Boolean, attribute: 'shadow'},
    };

    constructor() {
        super();
        this.imgpos = this.imgpos || 'top'
    }

    render() {
        let htmlContent = '';
        if(!this.image)     this.imgpos = 'top'
        var classes = {card: true, shadow: this.shadow}

        switch(this.imgpos)
        {
            case 'top':
                htmlContent = html`
                    <div class="${classMap(classes)}">
                        ${this.header? html`<div class="card-header">${this.header}</div>` : ''}
                        ${this.image?  html`<img src="${this.image}" class="card-img-top">` : html``}
                        <div class="card-body">
                            ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                            <div class="card-text"><slot/></div>
                        </div>
                        ${this.footer || this.href ? html`
                        <div class="card-footer d-flex flex-row justify-content-between bg-transparent">
                            <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                            ${(this.href)? html`<span class="ps-2"><a href="${this.href}" @click="${this._navigate}"><i class="icon-three-dots"></i></a></span>`: html``}
                        </div>` : html``}
                    </div>`
                break;

            case 'bottom':
                htmlContent = html`
                    <div class="${classMap(classes)}">
                        ${this.header? html`<div class="card-header">${this.header}</div>` : ''}
                        <div class="card-body">
                            ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                            <div class="card-text"><slot/></div>
                            ${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}
                        </div>
                        ${this.image?  html`<img src="${this.image}" class="card-img-bottom">` : html``}
                        ${this.footer || this.href ? html`
                        <div class="card-footer d-flex flex-row justify-content-between bg-transparent">
                            <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                            ${(this.href)? html`<span class="ps-2"><a href="${this.href}" @click="${this._navigate}"><i class="icon-three-dots"></i></a></span>`: html``}
                        </div>` : html``}
                    </div>`
                break

            case 'left':
                htmlContent = html`
                    <div class="${classMap(classes)}">
                        ${this.header? html`<div class="card-header">${this.header}</div>` : ''}
                        <div class="row g-0">
                            <div class="col-md-4"><img src="${this.image}" width="100%" class="card-img-left"></div>
                            <div class="col-md-8">
                                <div class="card-body">
                                    ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                                    <div class="card-text"><slot/></div>
                                </div>
                            </div>
                            ${this.footer || this.href ? html`
                            <div class="card-footer d-flex flex-row justify-content-between bg-transparent">
                                <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                                ${(this.href)? html`<span class="ps-2"><a href="${this.href}" @click="${this._navigate}"><i class="icon-three-dots"></i></a></span>`: html``}
                            </div>` : html``}
                        </div>
                    </div>`  
                break

                case 'right':
                    htmlContent = html`
                        <div class="${classMap(classes)}">
                            ${this.header? html`<div class="card-header">${this.header}</div>` : ''}
                            <div class="row g-0">
                                <div class="col-md-8">
                                    <div class="card-body">
                                        ${this.title? html`<h5 class="card-title">${this.title}</h5>` : html``}
                                        <div class="card-text"><slot/></div>
                                    </div>
                                </div>
                                <div class="col-md-4"><img src="${this.image}" width="100%" class="card-img-right"></div>
                                ${this.footer || this.href ? html`
                                <div class="card-footer d-flex flex-row justify-content-between bg-transparent">
                                    <span class="pe-2">${this.footer? html`<p class="card-text"><small class="text-body-secondary">${this.footer}</small></p>` : html``}</span>
                                    ${(this.href)? html`<span class="ps-2"><a href="${this.href}" @click="${this._navigate}"><i class="icon-three-dots"></i></a></span>`: html``}
                                </div>` : html``}
                            </div>
                        </div>`  
                    break
    
        }
        return html`
            ${super.render()}
            ${htmlContent}`
    }

    // --- custom functions 
    _navigate(e) {
        e.preventDefault()
        this.dispatchEvent(new CustomEvent('navigate', {bubbles: true, cancelable: true, detail: {url: this.href}}))
    }
}

if (!customElements.get('vx-imgcard')) 
    customElements.define('vx-imgcard', TImageCard);
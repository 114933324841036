export function get(url, params) {
    return new Promise ( async ( resolve, reject ) =>
    {
        if(params)  url = `${url}?` + new URLSearchParams(params).toString()
        console.log('get', url)
        fetch(url)
        .then(response => { 
            if(response.ok)     return response.json() 
            else throw new Error('HTTP failed..') })
        .then(body => { console.log('get => ', body); resolve(body) })
        .catch(error => {
            console.error('HTTP Error:', error)
            reject(error)
        })  
    })
}
  
export function post(url, data) {
    return new Promise ( async ( resolve ) =>
    {
        const payload = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data) }
        fetch(url, payload)
        .then(response => { 
            if(response.ok) response.json() 
            else throw new Error(response.text) })
        .then(data => { resolve(data) })
        .catch(error => {
            console.error('HTTP Error:', error)
            reject(error)
        })    
    })
}

export function send(url, method, data) {
    return new Promise ( async ( resolve, reject ) =>
    {
        const payload = {method, headers:{}}
        if(data) {
            if(['PUT', 'POST'].includes(method)) {
                payload.body = JSON.stringify(data)
                payload.headers['Content-Type'] = 'application/json'
            }
            else url = `${url}?` + new URLSearchParams(data).toString()
        }
        console.log('HTTP', method, url, payload)
        fetch(url, payload)
        .then(response => { 
            if(response.ok) return response.json() 
            else throw new Error(response.text) })
        .then(data => { resolve(data) })
        .catch(error => reject(error))  
    })
}

import {html, css} from 'lit'
import {TBase} from '../../wcl/base'

export class TDashboardView extends TBase 
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properies
    }

    constructor() {
        super()
    }

    render() {
        console.log('rendering dashboard')
        return html`
            ${super.render()}
            <div class="container-fluid pe-5 mt-5">
                <div class="row"><vx-card>Tableau de bord</vx-card></div>
                    <div class="row">
                        <div class="col-md-8 mt-3">
                            <vx-imgcard data-vx-image="/assets/img/infrastructure.png" data-vx-title="Infrastructure" data-vx-imgpos="right" @navigate="${this._navigate}" >
                                Cette interface vous offre un outil pour piloter votre plateforme de téléphonie moderne intégrant une multitude de fonctionnalités avancées, notamment un Session Border Controller (SBC), des  serveurs vocaux interactifs (SVI), des Call Bots, des enregistreurs de communication, des centres d'appel avec des pôles de compétences et des files d'attentes, les interco opérateurs avec trunks SIP ainsi que les postes WebRTC.
                            </vx-imgcard>
                        </div>
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-12 col-xl-6 mt-3"><vx-valuecard data-vx-icon="hdd-network" data-vx-title="SBC" data-vx-value="0" @navigate="${this._navigate}" href="/sbc"></vx-valuecard></div>
                                <div class="col-12 col-xl-6 mt-3"><vx-valuecard data-vx-icon="diagram-3" data-vx-title="SVI" data-vx-value="0" @navigate="${this._navigate}" href="/svi"></vx-valuecard></div>
                            </div>    
                        </div>
                    </div>
                        <div class="row">
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-valuecard data-vx-icon="chat-left-dots" data-vx-title="BOT" data-vx-value="0" @navigate="${this._navigate}" href="/sbc"></vx-valuecard></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-valuecard data-vx-icon="diagram-3" data-vx-title="SVI" data-vx-value="0" @navigate="${this._navigate}" href="/svi"></vx-valuecard></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-valuecard data-vx-icon="telephone" data-vx-title="RTC" data-vx-value="0" @navigate="${this._navigate}" href="/sbc"></vx-valuecard></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-valuecard data-vx-icon="hdd-rack" data-vx-title="TRUNK" data-vx-value="0" @navigate="${this._navigate}" href="/svi"></vx-valuecard></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-valuecard data-vx-icon="list-nested" data-vx-title="QUEUE" data-vx-value="0" @navigate="${this._navigate}" href="/sbc"></vx-valuecard></div>
                            <div class="col-6 col-md-4 col-xl-2 mt-3"><vx-valuecard data-vx-icon="rewind" data-vx-title="ENREG" data-vx-value="0" @navigate="${this._navigate}" href="/svi"></vx-valuecard></div>
                        </div>
                    </div>
                </div>
        `
    }

    _navigate(event)
    {
        console.log('dasbboard', "navigate", event)
        this.dispatchEvent(new CustomEvent('navigate', {bubbles: true, cancelable: true, detail: event.detail}))
    }
}

if (!customElements.get('vx-dashboard-view')) 
    customElements.define('vx-dashboard-view', TDashboardView);



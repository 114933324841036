import {html, css} from 'lit'
import {TBase} from '../../../wcl/base'

export class TTemplateListView extends TBase 
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properies,
        templates : { type: String, attribute: 'data-vx-templates' }
    }

    constructor() {
        super()
    }

//    { "name": "STRESS TEST SANS SVI", "transcription": "non", "speech_detection": "5 sec", "input_type": "aucun", "input_sequence": "" },

    render() {
        console.log('rendering template-list', this.templates)
        const columns = [{ data:'name', title: 'Nom' }, { data: 'creation', title: 'Date de création' }, { data: 'type', title: 'Type' }, { data: 'status', title: 'Statut' }]
        const header  = {text: 'Liste des Templates', buttons: [{action: 'create', icon: 'plus-square'}]}
        return html`
            ${super.render()}
            <div class="container-fluid pe-5 mt-5">
                    <div class="row"><vx-card>VSQV / TEMPLATES</vx-card></div>
                    <div class="row pt-3">
                        <vx-card
                            name="template"
                            @action=${this.relay}
                            data-vx-header=${JSON.stringify(header)}>
                            <vx-datatable
                                name="template" 
                                data-vx-datasrc="${this.templates}"
                                data-vx-cols=${JSON.stringify(columns)}
                                @action="${this.relay}"
                                striped hover>
                            </vx-datatable>
                        </vx-card>
                    </div>
                </div>
        `
    }

}

if (!customElements.get('vx-template-list-view')) 
    customElements.define('vx-template-list-view', TTemplateListView);



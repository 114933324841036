import {html, css} from 'lit'
import {TBase} from '../../../wcl/base'

export class TCampaignListView extends TBase 
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properies,
        campaigns : { type: String, attribute: 'data-vx-campaigns' }
    }

    constructor() {
        super()
    }

/*
    {
        "uid": "a715007b-f924-4e5e-8334-b8d0efa1e341",
        "owner": null,
        "template": {
            "uid": "11008137-b41a-46f3-ac5d-08fcf38ee0aa",
            "name": "template_1",
            "owner": "f7ff1e4d-af81-4516-9e4e-7d662d10423c",
            "creation": "2023-10-21T10:36:57.359Z",
            "data": {
                "transcription": true,
                "speech_detection": "",
                "input_type": "",
                "input_sequence": ""
            },
            "status": "enabled",
            "type": "Stress Test"
        },
        "did": "11008137-b41a-46f3-ac5d-08fcf38ee0aa",
        "calls": 200,
        "script": {},
        "description": "Campagne de test",
        "start": null,
        "end": null
    }
    */

    render() {
        console.log('rendering campaign-list', this.campaigns)
        const columns = [ 
            { data: 'creation', title: 'Date' }, 
            { data: 'name', title: 'Nom' }, 
            { data: 'template.name', title: 'Template' }, 
            { data: 'did.number', title: 'SDA' }, 
            { data: 'calls', title: 'Appels' }, 
            { data: 'status', title: 'Statut' } ]
        const header  = {text: 'Liste des Templates', buttons: [{action: 'create', icon: 'plus-square'}]}
        return html`
            ${super.render()}
            <div class="container-fluid pe-5 mt-5">
                    <div class="row"><vx-card>VSQV / CAMPAGNES</vx-card></div>
                    <div class="row pt-3">
                        <vx-card
                            name="campaign"
                            @action=${this.relay}
                            data-vx-header=${JSON.stringify(header)}>
                            <vx-datatable
                                name="campaign" 
                                data-vx-datasrc="${this.campaigns}"
                                data-vx-cols=${JSON.stringify(columns)}
                                striped hover>
                            </vx-datatable>
                        </vx-card>
                    </div>
                </div>
        `
    }
}

if (!customElements.get('vx-campaign-list-view')) 
    customElements.define('vx-campaign-list-view', TCampaignListView);



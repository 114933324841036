import {html, css, LitElement, unsafeCSS} from 'lit'
import {TBase} from '../base'

export class TLoginForm extends TBase
{
    static styles = css`
        :host {
        }
        
        .container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh; /* 100% of the viewport height */
            margin: 0;
        }
    `
    static properties = {};

    constructor() {
        super();
    }

    render() {
        const fields = [ { name:'username', type:'text', placeholder:"Nom d'utilisateur", required: true }, { name:'password', type:'password', placeholder:'Mot de passe', required: true} ]
        const button = [ { label: 'connection', action: 'submit', color: 'primary' } ]
        return html`
        <div class="container">
            <vx-card data-vx-header="Login" data-vx-shadow="shadow">
                <vx-form 
                    data-vx-col-width='12'
                    data-vx-fields='${JSON.stringify(fields)}' 
                    data-vx-buttons='${JSON.stringify(button)}'
                    @submit='${this._submit}'
                ></vx-form>
            </vx-card>
        </div>`
    }

    // --- custom functions 
    _submit(e) {
        e.stopPropagation()
        console.log('login event', e)
        this.dispatchEvent(new CustomEvent('login', {bubbles: true, cancelable: true, composed: true, detail: {sender: this,  credentials: e.detail.form.fields}}))
    }
}

if (!customElements.get('vx-login')) 
    customElements.define('vx-login', TLoginForm);

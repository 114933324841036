import {html, css} from 'lit'
import {TTable} from './table'
import DataTable from 'datatables.net-bs5';

export class TDataTable extends TTable 
{
    static styles = [
        TTable.styles,
        css``
    ]

    static properties = {
        ...TTable.properties
    }

    constructor() {
        super()
        this.dataTable = null
        this.cssLibs.push('/assets/css/datatables.bs.css')
    }

    updated(changedProperties) {
        console.log('Table rendering done.. Loading DataTable...', changedProperties)
        const settings = {
            language: {
                décimal: ",",
                thousands: " ",
                info: 'page _PAGE_ de _PAGES_',
                infoEmpty: 'Aucune donnée à afficher',
                infoFiltered: '(sélection de _MAX_ lignes)',
                lengthMenu: ' _MENU_ lignes par page',
                zeroRecords: 'Pas de résultats..',
                loadingRecords: "Chargement...",
                search: "rechercher",
                paginate: {
                    "first":      "Premier",
                    "last":       "Dernier",
                    "next":       "Suivant",
                    "previous":   "Précédent"
                },
                aria: {
                    "sortAscending":  ": activer pour trier les données de la colonne du plus petit au plus grand",
                    "sortDescending": ": activer pour trier les données de la colonne du plus grand au plus petit"
                }
            }
        } 
        const table  = this.shadowRoot.querySelector('table')
        console.log('table', table, typeof(table), table?.rows?.length)
        console.log('------------- (datatable) -------------')
        console.log('there are', this.shadowRoot.querySelectorAll('table').length, 'tables')
        console.log(this.shadowRoot)
        if(table?.rows?.length>1) {
            this.datatable = new DataTable(table, settings)
        }
    }

    render() {
        if(this.datatable)  this.datatable.destroy()
        return super.render()
    }
}

if (!customElements.get('vx-datatable')) 
    customElements.define('vx-datatable', TDataTable)
import { TRouter } from './router'
import { TSessionStorae } from './sotrage'
import * as http from '../http'

// app config
import config from './config'

// menu items
import {menu} from './menu'

// modals & popups
import {showAlertToast, showConfirmToast} from '../wcl/popups/toast'

// views
import './views/dashboard'
import './views/dids/list'
import './views/dids/edit'
import './views/users/list'
import './views/users/edit'
import './views/templates/list'
import './views/templates/edit'
import './views/campaigns/list'
import './views/campaigns/edit'
import './views/campaigns/stat'

export class TVoxyApp 
{
    constructor(name) {
        this.name = name
        this.session = new TSessionStorae()
        this.initialize()
        // window.sessionStorage.clear()
    }

    get user()  { return this.session.read('vx-user') }
    set user(u) { this.session.write('vx-user', u) }

    initialize() {
        this.router = new TRouter()
        this.router.on('route'   , (route, ctx) => this.request(route, ctx))
        this.router.on('login'   , (data) => this.login (data))
        this.router.on('submit'  , (data) => this.submit(data))
        this.router.on('action'  , (data) => this.action(data))
        this.router.on('navigate', (data) => this.navigate(data))
        this.router.initialize()
        this.router.start()
    }

    request(route, ctx) {
        console.log('VOXYAPP', 'requesting', route, ctx)
        if(route.login && !this.user)   this.router.goto('/login')
        else                            this.loadView(route.view, ctx.params)
    }

    loadView(view, params) {
        console.log('VOXYAPP', 'loadView', view, params)
        const getroot = () => {
            var root = document.getElementById('app-root')
            if(!root) {
                let layout  = document.createElement('vx-layout')
                let sidebar = document.createElement('vx-sidebar')
                let approot = document.createElement('div')
                sidebar.setAttribute('slot', 'sidebar')
                sidebar.setAttribute('data-vx-menu', JSON.stringify(menu))
                approot.setAttribute('id', 'app-root')
                approot.setAttribute('slot', 'content')
                layout.appendChild(sidebar)
                layout.appendChild(approot)
                document.body.innerHTML = ''
                document.body.append(layout)
                console.log('**** created approot')
                root = approot }
            return root
        }

        const loader = {
            campaign: {
                view: (uid) => {
                    console.log('view', uid)
                    let url = config.rest.route(`campaigns/${uid}`)
                    console.log('view', uid, url)
                    if(uid === 'new')   loader.campaign.edit(uid)
                    else http.get(url)
                        .then(campaign => {
                            console.log('campaign', campaign)
                            if(campaign.status === 'pending')   loader.campaign.edit(uid)
                            else                                loader.campaign.stat(uid)
                        })
                        .catch(e => loader.campaign.edit(uid))
                },
                edit: (uid) => {
                    console.log('VOXYAPP', "editing campaign")
                    var root = getroot()
                    root.innerHTML = ''
                    viewEl = document.createElement('vx-campaign-edit-view')
                    if(uid === 'new')   viewEl.setAttribute('data-vx-api', config.rest.route(`campaigns`))
                    else {
                        viewEl.setAttribute('data-vx-uid', uid)
                        viewEl.setAttribute('data-vx-resource', config.rest.route(`campaigns/${uid}`))
                        viewEl.setAttribute('data-vx-api', config.rest.route(`campaigns/${uid}`))
                    }
                    viewEl.setAttribute('data-vx-dids-list-url', config.rest.route('dids/short'))
                    viewEl.setAttribute('data-vx-templates-list-url', config.rest.route('templates/short'))
                    root.append(viewEl)
                    console.log('creating campaign', uid)    
                },
                stat: (uid) => {
                    // showAlertToast(`La campagne n'est pas encore lancée pour voir les stats.`)
                    console.log('VOXYAPP', "stats campaign")
                    var root = getroot()
                    root.innerHTML = ''
                    viewEl = document.createElement('vx-campaign-stat-view')
                    viewEl.setAttribute('data-vx-uid', uid)
                    viewEl.setAttribute('data-vx-url', config.rest.route(`campaigns/${uid}`))
                    root.append(viewEl)
                }  
            }
        }
        
        var viewEl
        switch(view) {
            case 'login':
                document.body.innerHTML = ''
                document.body.appendChild(document.createElement('vx-login'))
                break
            case 'dashboard':
                var root = getroot()
                root.innerHTML = ''
                root.append(document.createElement('vx-dashboard-view'))
                break
            case 'dids':
                var root = getroot()
                root.innerHTML = ''
                viewEl = document.createElement('vx-did-list-view')
                viewEl.setAttribute('data-vx-dids', config.rest.route('dids'))
                root.append(viewEl)
                break
            case 'templates':
                var root = getroot()
                root.innerHTML = ''
                viewEl = document.createElement('vx-template-list-view')
                viewEl.setAttribute('data-vx-templates', config.rest.route('templates'))
                root.append(viewEl)
                break
            case 'campaigns':
                var root = getroot()
                root.innerHTML = ''
                viewEl = document.createElement('vx-campaign-list-view')
                viewEl.setAttribute('data-vx-campaigns', config.rest.route('campaigns'))
                root.append(viewEl)
                break
            case 'users':
                var root = getroot()
                root.innerHTML = ''
                viewEl = document.createElement('vx-user-list-view')
                viewEl.setAttribute('data-vx-users', config.rest.route('users'))
                root.append(viewEl)
                break
            case 'user':
                var root = getroot()
                root.innerHTML = ''
                viewEl = document.createElement('vx-user-edit-view')
                if(params.uid === 'new')   viewEl.setAttribute('data-vx-api', config.rest.route(`users`))
                else {
                    console.log('not new user !', params.uid, params)
                    viewEl.setAttribute('data-vx-uid', params.uid)
                    viewEl.setAttribute('data-vx-resource', config.rest.route(`users/${params.uid}`))
                    viewEl.setAttribute('data-vx-api', config.rest.route(`users/${params.uid}`))
                }
                root.append(viewEl)
                console.log('creating user', params.uid)
                break
            case 'did':
                var root = getroot()
                root.innerHTML = ''
                viewEl = document.createElement('vx-did-edit-view')
                if(params.uid === 'new')   viewEl.setAttribute('data-vx-api', config.rest.route(`dids`))
                else {
                    console.log('not new did !', params.uid, params)
                    viewEl.setAttribute('data-vx-uid', params.uid)
                    viewEl.setAttribute('data-vx-resource', config.rest.route(`dids/${params.uid}`))
                    viewEl.setAttribute('data-vx-api', config.rest.route(`dids/${params.uid}`))
                }
                root.append(viewEl)
                console.log('creating did', params.uid)
                break
            case 'template':
                var root = getroot()
                root.innerHTML = ''
                viewEl = document.createElement('vx-template-edit-view')
                if(params.uid === 'new')   viewEl.setAttribute('data-vx-api', config.rest.route(`templates`))
                else {
                    viewEl.setAttribute('data-vx-uid', params.uid)
                    viewEl.setAttribute('data-vx-resource', config.rest.route(`templates/${params.uid}`))
                    viewEl.setAttribute('data-vx-api', config.rest.route(`templates/${params.uid}`))
                }
                root.append(viewEl)
                console.log('creating template', params.uid)
                break
            case 'campaign':
                loader.campaign.view(params.uid)
                break
            default:
                console.error(view, ': can not find view.')
        }
    }

    login(data) {
        console.log('VOXYAPP login', data)
        if(data.credentials) {
            http.get(config.rest.route('login'), data.credentials)
                .then(user => {
                    console.log('got user', user)
                    this.user = user
                    this.router.goto('/dashboard')        
                })
                .catch(e => showAlertToast("Erreur de connexion."))
        }
        else showAlertToast("veuillez saisir votre login et mot de passe.")
    }

    logout() {
        this.session.delete('vx-user')
        this.router.goto('/')
    }

    getNext() {
        const parts = window.location.pathname.split('/')
        parts.pop()
        return parts.join('/')
    }

    submit(data, next) {
        console.log('VOXYAPP submit', data.form)
        const url = data.form.url 
        const method = data.form.method 
        const fields = data.form.fields
        const nextvw = next || this.getNext()
        http.send(url, method, fields)
            .then((resp) => this.router.goto(nextvw))
            .catch(err => {
                console.error(err)
                showAlertToast(`L'opération n'a pu aboutir.`)
            })
    }

    action(data) {
        console.log('VOXYAPP action', data)
        switch(data.action) {
            case 'create':
                console.log('VOXYAPP', `create ${data.name}`, data)
                this.router.goto(`${window.location.pathname}/new`)
                break
            case 'edit':
                console.log('VOXYAPP', `edit ${data.name}`, data)
                this.router.goto(`${window.location.pathname}/${data.item.uid}`)
                break
            case 'delete':
                console.log('VOXYAPP', `delete ${data.name}`, data)
                this.router.goto(`${window.location.pathname}/${data.item.uid}`)
                break
            case 'start':
                console.log('VOXYAPP', `start ${data.name}`, data)
                data.form.fields['run'] = true
                this.submit(data)
                // showAlertToast(`La campagne ne peut être lancée.`)
                // this.router.goto(`/vsqv/campaigns`)
                break
            default:
                console.error('unknown action', data.action)
        }
    }

    navigate(data) {
        console.log("VOXYAPP", 'navigate', data)
        const route = this.router.routeFor(data.url)
        console.log(' ----->> route for', data.url, route)
        if(route.logout)    return this.logout()
        if(!route?.view)    showAlertToast('Ressource indisponible. Veuillez contacter votre administrateur système.')
        else                this.router.goto(data.url)
    }
}

window.voxyapp = new TVoxyApp()
window.router  = window.voxyapp.router




import {html, css, LitElement} from 'lit'
import { classMap } from 'lit/directives/class-map'
import {TBase} from '../base'

export class TCard extends TBase 
{
    static styles = [ 
        TBase.styles, 
        css`
            .card-header .btn,
            .card-footer .btn {
                --bs-btn-padding-y: 0rem; 
                --bs-btn-padding-x: .3rem; 
                --bs-btn-font-size: .75rem;
                --bs-btn-border-radius: 3px;
            }
        ` ]

    static properties = {
        ...TBase.properties,
        title : {type: String         , attribute: 'data-vx-title'},
        header: {/* string or object */ attribute: 'data-vx-header'},
        footer: {/* string or object */ attribute: 'data-vx-footer'},
        shadow: {type: Boolean        , attribute: 'shadow'}
    }

    constructor() {
        super()
    }

    render() {
        var self = this
        const parseAttribute = (attr) => {
            if(!attr)   return null
            var content = { text: '', buttons: [] }
            try {
                var json = JSON.parse(attr)
                content.text = json.text || ''
                content.buttons = (json.buttons || []).filter( button => button.action && (button.text || button.icon) )
            }
            catch(e) { content.text = attr }
            finally  { return content }
        } 
        const renderHeader = () => {
            console.log('renderHeader', self.header)
            var header = parseAttribute(self.header)
            return renderBlock('header', header)
        } 
        const rederFooter = () => {
            var footer = parseAttribute(self.footer)
            console.log('renderFooter', self.footer, footer)
            return renderBlock('footer', footer)
        }
        const renderBlock = (type, content) => {
            if(!content)    return ''
            return html`
            <div class="card-${type} d-flex justify-content-between">
            <div>${content.text}</div>
            <div class="btn-group" role="group">
                ${content.buttons.map( button => html`
                <button 
                    type="button" 
                    action=${button.action}
                    class="btn btn-light"
                    @click=${this._btnClick}>
                    <vx-icon name=${button.icon}/>
                    ${button.text ? html`${button.text}` : ''}
                </button>`)}
            </div>
        </div> 
            `
        }
        var classes = {card: true, shadow: this.shadow}
        return html`
        ${super.render()}
        <div class="${classMap(classes)}">
            ${renderHeader()}
            <div class="card-body">
                <slot></slot>
            </div>
            ${rederFooter()}
        </div>`    
    }

    // --- custom functions
    _btnClick(e) {
        e.preventDefault()
        let sender = e.composedPath()[0] || e.target
        let button = sender?.tagName?.toLowerCase() === 'button' ? sender : e.target.closest('BUTTON')
        let action = button?.getAttribute('action')
        this.dispatchEvent(new CustomEvent('action', {bubbles: true, cancelable: true, detail: { sender: this, name:this.name, action }}))
        this.dispatchEvent(new CustomEvent(action, {bubbles: true, cancelable: true, detail: { sender: this, name:this.name }}))
    }
}

if (!customElements.get('vx-card')) 
    customElements.define('vx-card', TCard)

import {html, css} from 'lit'
import {TBase} from '../../../wcl/base'


export class TDidEditView extends TBase 
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properies,
        uid : { type: String, attribute: 'data-vx-uid' },
        url : { type: String, attribute: 'data-vx-resource'},
        api : { type: String, attribute: 'data-vx-api'}
    }

    constructor() {
        super()
    }

    render() {
        console.log('rendering did-edit', this.uid)
        var fields = [
            { name: 'uid'           , type: 'hidden' },
            { name: 'name'          , type: 'text'   , label: 'Nom', required: true },
            { name: 'number'        , type: 'text'   , label: 'Numéro', prefix: '+', placeholder: "33000000000", pattern: "33(1|2|3|4|5|9)\\d{8}", required: true  },
            { name: 'description'   , type: 'textarea', label: 'Description'}
        ]
        var buttons = [{label: 'enregistrer', action: 'submit', color: 'primary'}]
        var datasrc = this.url
        var desturl = this.api
        if(this.uid !== 'new')  buttons.push({label: 'supprimer', action: 'delete', color: 'danger'})

        return html`
            ${super.render()}
            <div class="container-fluid pe-5 mt-5">
            <div class="row"><vx-card>UTILISATEURS / EDIT</vx-card></div>
            <div class="row pt-3">
                <vx-card
                    data-vx-header='Edition de fiche utilisateur'>
                    <vx-form
                        name='did'
                        method='POST'
                        url=${desturl}
                        data-vx-datasrc=${datasrc}
                        data-vx-fields=${JSON.stringify(fields)}
                        data-vx-buttons=${JSON.stringify(buttons)}
                    ></vx-form>
                </vx-card
        `
    }
}

if (!customElements.get('vx-did-edit-view')) 
    customElements.define('vx-did-edit-view', TDidEditView);

/*
    "uid": "001",
    "firstname": "Ahmed",
    "lastname": "Gabal",
    "email": "a.gabal@me.com",
    "tel": "07 89 77 14 01",
    "didname": "a.gabal@me.com",
    "password": "****"


    input.id            = input.id || `${input.type}-${uuid()}`
    input.type          = input.type || 'text'
    input.name          = input.name || input.id
    input.label         = input.label || ''
    input.value         = input.value || ''
    input.placeholder   = input.placeholder || input.label
    input.options       = input.options || []
*/
export function flatten (obj, pre) {
    var data = {}
    const prefix = (pre?.length)? `${pre}.` : ''
    const dttype = obj.constructor.name
    if (dttype === 'Array')     data = obj.map( item => flatten(item, pre) )
    else if (dttype === 'Object') {
        for (const [key, value] of Object.entries(obj)) {
            const attrib = `${prefix}${key}`
            if(value) {
                if(value.constructor.name === 'Object')         data = {...data, ...flatten(value, attrib)}
                else if(value.constructor.name === 'Array')     data[attrib] = value.map( val => flatten(val) )
                else                                            data[attrib] = value
            }
            else  data[attrib] = value
        }
    }
    else data = obj
    return data
}

export class TSessionStorae 
{
    constructor() {

    }

    read(key) {
        var value = sessionStorage.getItem(key)
        try { value = JSON.parse(value) } 
        catch(e) {}
        finally { return value }
    }

    write(key, value) {
        try {
            const data = (typeof value === 'object') ? JSON.stringify(value) : value
            sessionStorage.setItem(key, data);
        }
        catch(e) { console.error(e) }
    }

    update(key, value) {
        this.write(key, value)
    }

    delete(key) {
        sessionStorage.removeItem(key)
    }
}
import {html, css} from 'lit'
import { classMap } from 'lit/directives/class-map'
import { v4 as uuid } from 'uuid'
import {TBase} from '../base'
import * as http from '../../http'

const dummy = (e) => {}

export class TForm extends TBase 
{
    static styles = [ 
        TBase.styles, 
        css`
            input {
                width: 100%
            }
        ` ]

    static properties = {
        ...TBase.properties,
        floating    : {type: Boolean},  
        method      : {type: String},
        url         : {type: String},
        fields      : {type: Array, attribute: "data-vx-fields"},
        buttons     : {type: Array, attribute: "data-vx-buttons"},
        colwidth    : {type: Number, attribute: "data-vx-col-width"},
        ready       : {type: Boolean, attribute: false}
    };

    constructor() {
        super();
        this.fields = []
        this.buttons = [{label: 'submit', action: 'submit', color: 'primary'}]
        this.floating = false
        this.colwidth = 4
    }

    makeInput(input) {
        console.log('makeInput', input)
        input.id            = input.id || `${input.type}-${uuid()}`
        input.type          = input.type || 'text'
        input.name          = input.name || input.id
        input.label         = input.label || ''
        input.value         = input.value
        input.placeholder   = input.placeholder || input.label
        input.options       = input.options || []
        input.pattern       = input.pattern || '.*'
        if(input.value === undefined)   input.value = input.default 
        if(input.value === undefined)   input.value = ''

        switch(input.type) {
            case 'separator':
                return html`</div><div class="row">`
            case 'hidden':
                return html`
                <input 
                    id="${input.id}" 
                    name="${input.name}" 
                    type="hidden" 
                    value="${input.value}">`
            case 'select': 
                return html`
                    <div class="col-md-${this.colwidth}">
                        <div class="input-group mb-2">
                            <div class="w-100">
                                ${input.label? html`<label for="${input.id}">${input.label}</label>`: ''}
                                <select 
                                    id="${input.id}" 
                                    name="${input.name}" 
                                    class="form-select shadow-none" 
                                    aria-label="${input.label}"
                                    xvalue="${input.value}" 
                                    ?disabled="${input.disabled}"
                                    ?readonly="${input.readonly}"
                                    ?required="${input.required}">
                                    ${input.options.map(opt => {
                                        if(typeof opt === 'string') opt = {value: opt, label: opt}
                                        opt.value = opt.value || opt.label
                                        let selected = (opt.value === input.value)
                                        console.log('*** option', opt)
                                        return html`<option 
                                                        value="${opt.value}"
                                                        ?selected=${selected}>
                                                        ${opt.label}
                                                    </option>` })}
                                </select>
                            <div>
                    </div>`
            case 'textarea':
                return html`
                    <div class="col-md-12">
                        <div class="input-group mb-2">
                            <div class="w-100">
                                ${input.label? html`<label for="${input.id}">${input.label}</label>`: ''}
                                <textarea 
                                    id="${input.id}" 
                                    name="${input.name}" 
                                    type="${input.type}" 
                                    class="form-control shadow-none" 
                                    placeholder="${input.placeholder}"
                                    ?disabled="${input.disabled}"
                                    ?readonly="${input.readonly}"
                                    ?required="${input.required}">${input.value}</textarea>
                                </div>
                        </div>
                    </div>`
            case 'checkbox':
                return html`
                    <div class="col-md-${this.colwidth}">
                        <div class="input-group h-100 mb-2">
                            <div class="form-check form-switch h-100">
                                <input 
                                    id="${input.id}" 
                                    name="${input.name}" 
                                    type="${input.type}" 
                                    role="switch"
                                    class="form-check-input shadow-none" 
                                    value="true" 
                                    placeholder="${input.placeholder}"
                                    ?checked="${input.value}"
                                    ?disabled="${input.disabled}"
                                    ?readonly="${input.readonly}"
                                    ?required="${input.required}"/>
                                ${input.label? html`<label for="${input.id}" class="form-check-label">${input.label}</label>`: ''}
                            </div>
                        </div>
                    </div>`
            default:
                return html`
                    <div class="col-md-${this.colwidth}">
                        ${input.label? html`<label for="${input.id}">${input.label}</label>`: ''}
                        <div class="input-group mb-2">
                                ${input.prefix? html`<span class="input-group-text bg-transparent">${input.prefix}</span>` : ''}
                                <input 
                                    id="${input.id}" 
                                    name="${input.name}" 
                                    type="${input.type}" 
                                    class="form-control shadow-none" 
                                    pattern=${input.pattern}
                                    value="${input.value || input.default}" 
                                    placeholder="${input.placeholder}"
                                    ?disabled="${input.disabled}"
                                    ?readonly="${input.readonly}"
                                    ?required="${input.required}">
                                ${input.suffix? html`<span class="input-group-text bg-transparent">${input.suffix}</span>` : ''}
                            </div>
                    </div>`
        }
    }

    makeButton(button) {
        console.log('makeButton', button)
        button.id     = button.id || `btn-${uuid()}`
        button.label  = button.label  || ''
        button.icon   = button.icon   || null
        button.action = button.action || null
        button.color  = button.color  || 'primary'
        button.type   = button.action === 'submit' ? 'submit' : 'button'
        return html`
            <button 
                id="${button.id}" 
                class="btn btn-${button.color}" 
                type="${button.type}"
                action="${button.action}"
                @click="${button.type !== 'submit' ? this._action : dummy}"
            >${button.label}</button>`

            //${button.action !== 'submit'? html`@click="${this._action}"`: ''}
    }

    async firstUpdated() {
        super.firstUpdated()
        console.log('FORM first updated..')
        console.log('FORM inputs', this.fields)
        for(let field of this.fields.filter(field => field.type === 'select')) {
            if(field.datasrc) {
                try { 
                    let options = await http.get(field.datasrc)
                    field.options = []
                    console.log(field.name, 'OPTIONS -> ', options) 
                    for(let val in options) 
                        field.options.push({label: options[val], value: val})
                }
                catch(e) { console.error(e) }
            }
        }
        this.ready = true
    }

    render() {
        console.log('FORM rendering..', this.fields)
        console.log('form data', this.data)
        var self = this
        // fill field values
        if(this.fields && this.data) 
            this.fields.forEach( field => field.value = this.data[field.name] )

        return html`
            ${super.render()}
            <form @submit="${this._submit}">
                <div class="row">
                    ${this.fields.map( input => self.makeInput(input))}
                </div>
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                    ${this.buttons.map( button => self.makeButton(button))}
                </div>
            </form>`
    }

    // --- custom functions 
    compileData(form){
        const setValue = (keys, value, data) => {
            if(['true', 'false'].includes(value))   
                value = Boolean(value)
            const tree = keys.split('.')
            const name = tree.pop()
            var root = data
            for(let key of tree) {
              root[key] = root[key] || {}
              root = root[key]
            }
            root[name] = value
            return data
        }
        let data = {}
        const fields = new FormData(form)
        fields.forEach((value, keys) => { data = setValue(keys, value, data) })
        return data
    }

    _submit(e) {
        e.preventDefault()
        console.log('submitting', e.target)
        var form = { name: this.name, method: this.method, url: this.url, fields: this.compileData(e.target) }
        // const data = new FormData(e.target)
        // data.forEach((value, key) => form.fields[key] = value)
        console.log('submitted', form)
        this.dispatchEvent(new CustomEvent('submit', {bubbles: true, cancelable: true, composed: true, detail: {sender: this,  form}}))
    }

    _action(e) {
        e.preventDefault()
        console.log('action', e.target)
        var action = e.target.getAttribute('action')
        var formEl = this.shadowRoot.querySelector('form')
        var form = { name: this.name, method: this.method, url: this.url, fields: this.compileData(formEl) }
        if(['get', 'post', 'put', 'delete'].includes(action.toLowerCase())) {
            console.log('submitted', form)
            form.method = action.toUpperCase()
            this.dispatchEvent(new CustomEvent('submit', {bubbles: true, cancelable: true, composed: true, detail: {sender: this,  form}}))    
        }
        else {
            // var item = this.compileData(formEl)
            console.log('action', action, form)    
            this.dispatchEvent(new CustomEvent('action', {bubbles: true, cancelable: true, composed: true, detail: {sender: this, name: this.name, action, item: form.fields, form}}))
        }
    }

}

if (!customElements.get('vx-form')) 
    customElements.define('vx-form', TForm);

/**
 * <vx-form data-fields='[{"type":"text","label":"MyName","value":"Paul"},{"type":"text","label":"Amount","prefix":"€"},{"type":"select","label":"select me","options":[{"label":"option 1","value":"1"},{"label":"option 2","value":"option 2"}]}]'></vx-form>
 */
import {html, css, LitElement} from 'lit'
import { classMap } from 'lit/directives/class-map'
import {TBase} from '../base'

export class TBasicSidebar extends TBase 
{
    static styles = [ 
        TBase.styles, 
        css`
            :root {
            }
            ul {
                list-style-type: none;
                padding: 0 1rem;
            }
            li {
                margin-bottom: .5vh;
            }
        ` ]

    static properties = {
        ...TBase.properties,
        logo  : {type: String, attribute: 'data-vx-logo'},
        title : {type: String, attribute: 'data-vx-title'},
        menu  : {type: Array , attribute: 'data-vx-menu'}
    }

    constructor() {
        super()
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('navigate', this._selectItem);
    }
    
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('navigate', this._selectItem);
    }
    
    render() {
        console.log('menu', this.menu)
        console.log('title', this.title)
        return html`
            ${super.render()}
            <br/>
            ${this.menu.map( item => { 
                console.log('menu item'); 
                return html`
                    <ul><li>${item.title}</li>
                    ${item.items.map(sub => html`<li><vx-menu-item data-vx-icon="${sub.icon}" href="${sub.href}">${sub.text}</vx-menu-item></li>`) }
                    `               
            })}
        `
    }

    _selectItem(event) {
        this.shadowRoot.querySelectorAll('vx-menu-item').forEach( el => el.active = false)
        event.detail.sender.active = true
    }
}

if (!customElements.get('vx-sidebar')) 
    customElements.define('vx-sidebar', TBasicSidebar)
export const menu = [
    {
        title: 'infrastructure', 
        items: [
            { icon: 'speedometer2'  , href:'/dashboard', text: 'Tableau de bord' },
            { icon: 'hdd-network'   , href:'/sbc'       , text: 'SBC'               , error: "vous n'avez aucun SBC à configurer" },
            { icon: 'diagram-3'     , href:'/svi'       , text: 'SVI'               , error: "vous n'avez aucun SVI à configurer"},
            { icon: 'chat-left-dots', href:'/bot'       , text: 'BOT'               , error: "vous n'avez aucun BOT à configurer"},
            { icon: 'telephone'     , href:'/rtc'       , text: 'RTC'               , error: "vous n'avez aucune licence webRTC"},
            { icon: 'hdd-rack'      , href:'/trunk'     , text: 'Interco'           , error: "vous n'avez aucun SIP TRUNK à configurer"},
            { icon: 'list-nested'   , href:'/queue'     , text: "Centre d'appels"   , error: "vous n'avez aucune licence centre d'appels"},
            { icon: 'rewind'        , href:'/rec'       , text: 'Enregistreur'      , error: "vous n'avez aucun enregistreur à configurer"}
        ]
    },
    {
        title: 'Contrôle Qualité',
        items: [
//            { icon: 'speedometer2'  , href: '/vsqv'          , text: 'Tableau de bord'},
            { icon: 'dice-6'        , href: '/vsqv/dids'     , text: 'SDA'},
            { icon: 'toggles'       , href: '/vsqv/templates', text: 'Templates'},
            { icon: 'send'          , href: '/vsqv/campaigns', text: 'Campagnes'},
        ]
    },
    {
        title: 'Administration',
        items: [
            { icon: 'people', href: '/users'                , text: 'Utilisateurs'},
            { icon: 'chevron-left', href: '/logout'         , text: 'Déconnexion'},
        ]
    }
]

import {html, css} from 'lit'
import {TBase} from '../base'


export class TButton extends TBase {
    static styles = css `
        :host {      
            --vx-border-radius: 6px   
        }
        button {
            margin: 0;
            font-family: inherit;
            font-size: inherit;
            line-height: inherit;
        }

        button {
            text-transform: none;
        }

        .btn-check {
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none;
        }
        
        .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
            pointer-events: none;
            filter: none;
            opacity: 0.65;
        }

        .btn {
            --vx-btn-padding-x: 0.75rem;
            --vx-btn-padding-y: 0.375rem;
            --vx-btn-font-family: ;
            --vx-btn-font-size: 1rem;
            --vx-btn-font-weight: 400;
            --vx-btn-line-height: 1.5;
            --vx-btn-color: var(--vx-body-color);
            --vx-btn-bg: transparent;
            --vx-btn-border-width: var(--vx-border-width);
            --vx-btn-border-color: transparent;
            --vx-btn-border-radius: var(--vx-border-radius);
            --vx-btn-hover-border-color: transparent;
            --vx-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
            --vx-btn-disabled-opacity: 0.65;
            --vx-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--vx-btn-focus-shadow-rgb), .5);   

            display: inline-block;
            padding: var(--vx-btn-padding-y) var(--vx-btn-padding-x);
            font-family: var(--vx-btn-font-family);
            font-size: var(--vx-btn-font-size);
            font-weight: var(--vx-btn-font-weight);
            line-height: var(--vx-btn-line-height);
            color: var(--vx-btn-color);
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            border: var(--vx-btn-border-width) solid var(--vx-btn-border-color);
            border-radius: var(--vx-btn-border-radius);
            background-color: var(--vx-btn-bg);
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
            @media (prefers-reduced-motion: reduce) {
            .btn {
                transition: none;
            }
            }
            .btn:hover {
            color: var(--vx-btn-hover-color);
            background-color: var(--vx-btn-hover-bg);
            border-color: var(--vx-btn-hover-border-color);
            }
            .btn-check + .btn:hover {
            color: var(--vx-btn-color);
            background-color: var(--vx-btn-bg);
            border-color: var(--vx-btn-border-color);
            }
            .btn:focus-visible {
            color: var(--vx-btn-hover-color);
            background-color: var(--vx-btn-hover-bg);
            border-color: var(--vx-btn-hover-border-color);
            outline: 0;
            box-shadow: var(--vx-btn-focus-box-shadow);
            }
            .btn-check:focus-visible + .btn {
            border-color: var(--vx-btn-hover-border-color);
            outline: 0;
            box-shadow: var(--vx-btn-focus-box-shadow);
            }
            .btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
            color: var(--vx-btn-active-color);
            background-color: var(--vx-btn-active-bg);
            border-color: var(--vx-btn-active-border-color);
            }
            .btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
            box-shadow: var(--vx-btn-focus-box-shadow);
            }
            .btn:disabled, .btn.disabled, fieldset:disabled .btn {
                color: var(--vx-btn-disabled-color);
                pointer-events: none;
                background-color: var(--vx-btn-disabled-bg);
                border-color: var(--vx-btn-disabled-border-color);
                opacity: var(--vx-btn-disabled-opacity);
            }

            .btn-primary {
            --vx-btn-color: #fff;
            --vx-btn-bg: #0d6efd;
            --vx-btn-border-color: #0d6efd;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #0b5ed7;
            --vx-btn-hover-border-color: #0a58ca;
            --vx-btn-focus-shadow-rgb: 49, 132, 253;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #0a58ca;
            --vx-btn-active-border-color: #0a53be;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #fff;
            --vx-btn-disabled-bg: #0d6efd;
            --vx-btn-disabled-border-color: #0d6efd;
            }

            .btn-secondary {
            --vx-btn-color: #fff;
            --vx-btn-bg: #6c757d;
            --vx-btn-border-color: #6c757d;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #5c636a;
            --vx-btn-hover-border-color: #565e64;
            --vx-btn-focus-shadow-rgb: 130, 138, 145;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #565e64;
            --vx-btn-active-border-color: #51585e;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #fff;
            --vx-btn-disabled-bg: #6c757d;
            --vx-btn-disabled-border-color: #6c757d;
            }

            .btn-success {
            --vx-btn-color: #fff;
            --vx-btn-bg: #198754;
            --vx-btn-border-color: #198754;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #157347;
            --vx-btn-hover-border-color: #146c43;
            --vx-btn-focus-shadow-rgb: 60, 153, 110;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #146c43;
            --vx-btn-active-border-color: #13653f;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #fff;
            --vx-btn-disabled-bg: #198754;
            --vx-btn-disabled-border-color: #198754;
            }

            .btn-info {
            --vx-btn-color: #000;
            --vx-btn-bg: #0dcaf0;
            --vx-btn-border-color: #0dcaf0;
            --vx-btn-hover-color: #000;
            --vx-btn-hover-bg: #31d2f2;
            --vx-btn-hover-border-color: #25cff2;
            --vx-btn-focus-shadow-rgb: 11, 172, 204;
            --vx-btn-active-color: #000;
            --vx-btn-active-bg: #3dd5f3;
            --vx-btn-active-border-color: #25cff2;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #000;
            --vx-btn-disabled-bg: #0dcaf0;
            --vx-btn-disabled-border-color: #0dcaf0;
            }

            .btn-warning {
            --vx-btn-color: #000;
            --vx-btn-bg: #ffc107;
            --vx-btn-border-color: #ffc107;
            --vx-btn-hover-color: #000;
            --vx-btn-hover-bg: #ffca2c;
            --vx-btn-hover-border-color: #ffc720;
            --vx-btn-focus-shadow-rgb: 217, 164, 6;
            --vx-btn-active-color: #000;
            --vx-btn-active-bg: #ffcd39;
            --vx-btn-active-border-color: #ffc720;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #000;
            --vx-btn-disabled-bg: #ffc107;
            --vx-btn-disabled-border-color: #ffc107;
            }

            .btn-danger {
            --vx-btn-color: #fff;
            --vx-btn-bg: #dc3545;
            --vx-btn-border-color: #dc3545;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #bb2d3b;
            --vx-btn-hover-border-color: #b02a37;
            --vx-btn-focus-shadow-rgb: 225, 83, 97;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #b02a37;
            --vx-btn-active-border-color: #a52834;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #fff;
            --vx-btn-disabled-bg: #dc3545;
            --vx-btn-disabled-border-color: #dc3545;
            }

            .btn-light {
            --vx-btn-color: #000;
            --vx-btn-bg: #f8f9fa;
            --vx-btn-border-color: #f8f9fa;
            --vx-btn-hover-color: #000;
            --vx-btn-hover-bg: #d3d4d5;
            --vx-btn-hover-border-color: #c6c7c8;
            --vx-btn-focus-shadow-rgb: 211, 212, 213;
            --vx-btn-active-color: #000;
            --vx-btn-active-bg: #c6c7c8;
            --vx-btn-active-border-color: #babbbc;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #000;
            --vx-btn-disabled-bg: #f8f9fa;
            --vx-btn-disabled-border-color: #f8f9fa;
            }

            .btn-dark {
            --vx-btn-color: #fff;
            --vx-btn-bg: #212529;
            --vx-btn-border-color: #212529;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #424649;
            --vx-btn-hover-border-color: #373b3e;
            --vx-btn-focus-shadow-rgb: 66, 70, 73;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #4d5154;
            --vx-btn-active-border-color: #373b3e;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #fff;
            --vx-btn-disabled-bg: #212529;
            --vx-btn-disabled-border-color: #212529;
            }

            .btn-outline-primary {
            --vx-btn-color: #0d6efd;
            --vx-btn-border-color: #0d6efd;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #0d6efd;
            --vx-btn-hover-border-color: #0d6efd;
            --vx-btn-focus-shadow-rgb: 13, 110, 253;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #0d6efd;
            --vx-btn-active-border-color: #0d6efd;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #0d6efd;
            --vx-btn-disabled-bg: transparent;
            --vx-btn-disabled-border-color: #0d6efd;
            --vx-gradient: none;
            }

            .btn-outline-secondary {
            --vx-btn-color: #6c757d;
            --vx-btn-border-color: #6c757d;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #6c757d;
            --vx-btn-hover-border-color: #6c757d;
            --vx-btn-focus-shadow-rgb: 108, 117, 125;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #6c757d;
            --vx-btn-active-border-color: #6c757d;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #6c757d;
            --vx-btn-disabled-bg: transparent;
            --vx-btn-disabled-border-color: #6c757d;
            --vx-gradient: none;
            }

            .btn-outline-success {
            --vx-btn-color: #198754;
            --vx-btn-border-color: #198754;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #198754;
            --vx-btn-hover-border-color: #198754;
            --vx-btn-focus-shadow-rgb: 25, 135, 84;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #198754;
            --vx-btn-active-border-color: #198754;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #198754;
            --vx-btn-disabled-bg: transparent;
            --vx-btn-disabled-border-color: #198754;
            --vx-gradient: none;
            }

            .btn-outline-info {
            --vx-btn-color: #0dcaf0;
            --vx-btn-border-color: #0dcaf0;
            --vx-btn-hover-color: #000;
            --vx-btn-hover-bg: #0dcaf0;
            --vx-btn-hover-border-color: #0dcaf0;
            --vx-btn-focus-shadow-rgb: 13, 202, 240;
            --vx-btn-active-color: #000;
            --vx-btn-active-bg: #0dcaf0;
            --vx-btn-active-border-color: #0dcaf0;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #0dcaf0;
            --vx-btn-disabled-bg: transparent;
            --vx-btn-disabled-border-color: #0dcaf0;
            --vx-gradient: none;
            }

            .btn-outline-warning {
            --vx-btn-color: #ffc107;
            --vx-btn-border-color: #ffc107;
            --vx-btn-hover-color: #000;
            --vx-btn-hover-bg: #ffc107;
            --vx-btn-hover-border-color: #ffc107;
            --vx-btn-focus-shadow-rgb: 255, 193, 7;
            --vx-btn-active-color: #000;
            --vx-btn-active-bg: #ffc107;
            --vx-btn-active-border-color: #ffc107;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #ffc107;
            --vx-btn-disabled-bg: transparent;
            --vx-btn-disabled-border-color: #ffc107;
            --vx-gradient: none;
            }

            .btn-outline-danger {
            --vx-btn-color: #dc3545;
            --vx-btn-border-color: #dc3545;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #dc3545;
            --vx-btn-hover-border-color: #dc3545;
            --vx-btn-focus-shadow-rgb: 220, 53, 69;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #dc3545;
            --vx-btn-active-border-color: #dc3545;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #dc3545;
            --vx-btn-disabled-bg: transparent;
            --vx-btn-disabled-border-color: #dc3545;
            --vx-gradient: none;
            }

            .btn-outline-light {
            --vx-btn-color: #f8f9fa;
            --vx-btn-border-color: #f8f9fa;
            --vx-btn-hover-color: #000;
            --vx-btn-hover-bg: #f8f9fa;
            --vx-btn-hover-border-color: #f8f9fa;
            --vx-btn-focus-shadow-rgb: 248, 249, 250;
            --vx-btn-active-color: #000;
            --vx-btn-active-bg: #f8f9fa;
            --vx-btn-active-border-color: #f8f9fa;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #f8f9fa;
            --vx-btn-disabled-bg: transparent;
            --vx-btn-disabled-border-color: #f8f9fa;
            --vx-gradient: none;
            }

            .btn-outline-dark {
            --vx-btn-color: #212529;
            --vx-btn-border-color: #212529;
            --vx-btn-hover-color: #fff;
            --vx-btn-hover-bg: #212529;
            --vx-btn-hover-border-color: #212529;
            --vx-btn-focus-shadow-rgb: 33, 37, 41;
            --vx-btn-active-color: #fff;
            --vx-btn-active-bg: #212529;
            --vx-btn-active-border-color: #212529;
            --vx-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
            --vx-btn-disabled-color: #212529;
            --vx-btn-disabled-bg: transparent;
            --vx-btn-disabled-border-color: #212529;
            --vx-gradient: none;
            }

            .btn-link {
            --vx-btn-font-weight: 400;
            --vx-btn-color: var(--vx-link-color);
            --vx-btn-bg: transparent;
            --vx-btn-border-color: transparent;
            --vx-btn-hover-color: var(--vx-link-hover-color);
            --vx-btn-hover-border-color: transparent;
            --vx-btn-active-color: var(--vx-link-hover-color);
            --vx-btn-active-border-color: transparent;
            --vx-btn-disabled-color: #6c757d;
            --vx-btn-disabled-border-color: transparent;
            --vx-btn-box-shadow: 0 0 0 #000;
            --vx-btn-focus-shadow-rgb: 49, 132, 253;
            text-decoration: underline;
            }
            .btn-link:focus-visible {
            color: var(--vx-btn-color);
            }
            .btn-link:hover {
            color: var(--vx-btn-hover-color);
            }

            .btn-lg, .btn-group-lg > .btn {
            --vx-btn-padding-y: 0.5rem;
            --vx-btn-padding-x: 1rem;
            --vx-btn-font-size: 1.25rem;
            --vx-btn-border-radius: var(--vx-border-radius-lg);
            }

            .btn-sm, .btn-group-sm > .btn {
            --vx-btn-padding-y: 0.25rem;
            --vx-btn-padding-x: 0.5rem;
            --vx-btn-font-size: 0.875rem;
            --vx-btn-border-radius: var(--vx-border-radius-sm);
            }
    `;

    static properties = {
        href : {type: String},
        color: {type: String, attribute: "data-vx-color"},
        active:{type: Boolean, attribute: "data-vx-active"} 
    };

    constructor() {
        super()
        this.color = "primary"
    }

    render() {
        let classes = []
        if(this.color)  classes.push(`btn btn-${this.color}`)
        if(this.active) classes.push('active')
        return html `
            <button type="button" class="${classes.join(' ')}" @click="${this._click}">this is a button</button>
        `
    }

    // --- custom functions 
    _click(e) {
        e.preventDefault()
        if(this.href)   this.dispatchEvent(new CustomEvent('navigate', {detail: {url: this.href}}))
        else            this.dispatchEvent(new CustomEvent('click'))
    }
}

if (!customElements.get('vx-button'))
    customElements.define('vx-button', TButton);
import {html, css} from 'lit'
import {TBase} from '../../../wcl/base'


export class TCampaignEditView extends TBase 
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properies,
        uid : { type: String, attribute: 'data-vx-uid' },
        url : { type: String, attribute: 'data-vx-resource'},
        api : { type: String, attribute: 'data-vx-api'},
        dids     : { type: String, attribute: 'data-vx-dids-list-url' },
        templates: { type: String, attribute: 'data-vx-templates-list-url' },
    }

    constructor() {
        super()
    }

    render() {
        console.log('rendering campaign-edit', this.uid)
        var fields = [
            { name: 'uid'               , type: 'hidden' },
            { name: 'name'              , type: 'text'          , label: 'Nom',  required: true  },
            { type: 'separator' },
            { name: 'template'          , type: 'select'        , label: 'template'         , datasrc: this.templates},
            { type: 'separator' },
            { name: 'did'               , type: 'select'        , label: 'SDA'              , datasrc: this.dids},
            { name: 'calls'             , type: 'number'        , label: `Nombre d'appels`  , suffix: "appels",  required: true  },
            { name: 'description'       , type: 'textarea'      , label: 'Commentaires'     }
        ]
        var buttons = [{label: 'enregistrer', action: 'submit', color: 'primary'}, {label: 'lancer', action: 'start', color: 'primary'}]
        var datasrc = this.url
        var desturl = this.api
        if(this.uid)  buttons.push({label: 'supprimer', action: 'delete', color: 'danger'})

        return html`
            ${super.render()}
            <div class="container-fluid pe-5 mt-5">
            <div class="row"><vx-card>CAMPAGNES / EDIT</vx-card></div>
            <div class="row pt-3">
                <vx-card
                    data-vx-header='Edition de fiche utilisateur'>
                    <vx-form
                        name='campaign'
                        method='POST'
                        url=${desturl}
                        data-vx-datasrc=${datasrc}
                        data-vx-fields=${JSON.stringify(fields)}
                        data-vx-buttons=${JSON.stringify(buttons)}
                    ></vx-form>
                </vx-card>
            </div>
        `
    }
}

if (!customElements.get('vx-campaign-edit-view')) 
    customElements.define('vx-campaign-edit-view', TCampaignEditView);

/*
    "uid": "001",
    "firstname": "Ahmed",
    "lastname": "Gabal",
    "email": "a.gabal@me.com",
    "tel": "07 89 77 14 01",
    "campaignname": "a.gabal@me.com",
    "password": "****"


    input.id            = input.id || `${input.type}-${uuid()}`
    input.type          = input.type || 'text'
    input.name          = input.name || input.id
    input.label         = input.label || ''
    input.value         = input.value || ''
    input.placeholder   = input.placeholder || input.label
    input.options       = input.options || []
*/
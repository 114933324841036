import { html, css, LitElement, unsafeCSS } from 'lit'
import { flatten } from './tools';

export class TBase extends LitElement 
{
    static styles = css``;

    static properties = {
        name   : { type: String },
        cssLibs: { type: Array , attribute: false },
        dataSrc: { type: String, attribute: 'data-vx-datasrc'},
        refresh: { type: Number, attribute: 'data-vx-refresh'},
        data   : { attribute: false }
    };

    constructor() {
        super()
        this.cssLibs = [ "https://matmut.voxytel.fr/assets/css/bootstrap.css", "https://matmut.voxytel.fr/assets/icons/icons.css" ]
        this.timer = null
    }

    toCSV() {
        console.log('toCSV', `${this.dataSrc}/csv`)
        if(!this.dataSrc)   return 

        var self = this
        var url = this.dataSrc
        var payload = { method: 'GET', headers: { Accept: 'text/csv' } }
        if(window._vxtoken)    payload.headers['Authorization'] = `Bearer ${token}`

        fetch(url, payload)
            .then(response => {
                if (!response.ok)   throw new Error('Network response was not ok')
                return response.text()
            })
            .then(data => {
                console.log(self.name, 'fetched data', data)
                const blob = new Blob([data], { type: 'text/csv' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${this.name}.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => console.error(self.name, 'Error:', error) )
    }

    fetchData() {
        console.log('fetchData', this.dataSrc)
        if(!this.dataSrc)   return 

        var self = this
        var url = this.dataSrc
        var payload = { method: 'GET', headers: {} }
        if(window._vxtoken)    payload.headers['Authorization'] = `Bearer ${token}`

        fetch(url, payload)
            .then(response => {
                if (!response.ok)   throw new Error('Network response was not ok')
                return response.json()
            })
            .then(data => {
                console.log(self.name, 'fetched data', data)
                self.data = flatten(data)
                console.log(self.name, 'flattened data', self.data)
                if(self.refresh)    
                    self.timer = setTimeout(() => self.fetchData(), self.refresh)
            })
            .catch((error) => console.error(self.name, 'Error:', error) )
    }

    firstUpdated() {
    // connected() {
        super.firstUpdated()
        console.log(this.constructor.name, this.name, 'first updated !', this.cssLibs, this.dataSrc)
        this.fetchData()
    }

    propertyChangedCallback(name, oldVal, newVal) {
        super.propertyChangedCallback(name, oldVal, newVal);
        console.log(name, oldVal, newVal)
    }

    disconnectedCallback() {
        super.disconnectedCallback()
        if(this.timer)      clearTimeout(this.timer)
        this.timer = null
    }

    xupdated(changedProperties) {
        console.log('Table rendering done.. Loading DataTable...', changedProperties)

        var self = this
        const vxElements = this.renderRoot.querySelectorAll('[data-tag^="vx"]')
        vxElements.forEach(el => el.addEventListener('submit', e => self.dispatchEvent(new CustomEvent('submit', {detail: e.details}))))
    }

    relay(event) {
        console.log(this.constructor.name, 'relay event', event.type, event)
        this.dispatchEvent(new CustomEvent(event.type, {bubbles: true, cancelable: true, detail: event.detail}))
    }

    render() {
        console.log('data source :',this.dataSrc)
        return html`
            ${this.cssLibs.map( lib => html`
                <link rel="stylesheet" href="${lib}">`)}
        `
    }
}

if (!customElements.get('vx-base')) 
    customElements.define('vx-base', TBase)
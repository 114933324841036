import {html, css} from 'lit'
import {TBase} from '../../../wcl/base'


export class TCampaignStatView extends TBase 
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properies,
        uid : { type: String, attribute: 'data-vx-uid' },
        url : { type: String, attribute: 'data-vx-url'},
    }

    constructor() {
        super()
    }

    // chart    : {attribute: false},
    // title    : {type: String, attribute: 'data-vx-title'},
    // type     : {type: String, attribute: 'data-vx-type'},
    // options  : {type: Object, attribute: 'data-vx-options'},
    // data     : {type: Object, attribute: 'data-vx-data'},
    // labels   : {type: Array , attribute: 'data-vx-labels'},
    // axis     : {type: Object, attribute: 'data-vx-axis'}

    render() {
        console.log('rendering campaign-stat', this.uid)
        let results = [
            { data: 'NORMAL_CLEARING', title: 'aboutit', color: 'cadetblue' },
            { data: 'NO_ANSWER', title: 'pas de réponse', color: 'orange' },
            { data: 'BUSY', title: 'occupé', color: 'darkorange' },
            { data: 'FAILED', title: 'échec', color: 'darkred' },
            { data: 'UNREACHABLE', title: 'non-aboutit', color: 'red' },
            { data: 'PENDING', title: 'en cours', color: 'grey' } ]
        let speech = [
            { data: 'voice', title: 'voix détectée', color: 'cadetblue' },
            { data: 'silence', title: 'pas de voix', color: 'red' } ]
        let answer = [{ title: "évolution du temps de réponse (en sec)", color: "cadetblue"}]
        let calls  = [
            { data: 'start', title: 'horodatage' },
            { data: 'duration', title: 'durée' },
            { data: 'caller', title: 'appelant'},
            { data: 'called', title: 'SDA'},
            { data: 'status', title: 'code réseau'},
            { data: 'voice', title: 'voix détectée'}]
        let header = {text: 'Liste des appels', buttons: [{action: 'export', icon: 'filetype-csv'}]}

        return html`
            ${super.render()}
            <div class="container-fluid pe-5 mt-5">
            <div class="row"><vx-card>CAMPAGNE / RÉSULTATS</vx-card></div>

            <div class="row mt-3">

                <div class="col-xl-3 col-6">
                    <vx-card
                        data-vx-header='Statut des appels'>
                        <vx-chart
                            name="call status"
                            data-vx-title="Statut"
                            data-vx-type="pie"
                            data-vx-labels=${JSON.stringify(results)}
                            data-vx-datasrc="${this.url}/stats/result">
                        </vx-chart>
                    </vx-card>
                </div>

                <div class="col-xl-3 col-6">
                    <vx-card
                        data-vx-header='Détection de parole'>
                        <vx-chart
                            name="détection de parole"
                            data-vx-title="parole"
                            data-vx-type="pie"
                            data-vx-labels=${JSON.stringify(speech)}
                            data-vx-datasrc="${this.url}/stats/speech">
                        </vx-chart>
                    </vx-card>
                </div>

                <div class="col-xl-6 col-12">
                    <vx-card
                        data-vx-header='Délais de réponse'>
                        <vx-chart
                            name="answer time"
                            data-vx-title="Durée"
                            data-vx-type="bar"
                            data-vx-axis=${JSON.stringify(answer)}
                            data-vx-datasrc="${this.url}/stats/answer">
                        </vx-chart>
                    </vx-card>
                </div>

            </div>
            
            <div class="row mt-3">
                <vx-card
                    data-vx-header=${JSON.stringify(header)}
                    @export=${this._exportCalls}>
                    <vx-datatable
                        id="list-calls"
                        name="calls" 
                        data-vx-datasrc="${this.url}/calls"
                        data-vx-cols=${JSON.stringify(calls)}
                        striped hover>
                    </vx-datatable>
                </vx-card>
            </div>

        `
    }

    _exportCalls(e) {
        console.log('export Calls')
        this.renderRoot.getElementById('list-calls').toCSV()
    }
}

if (!customElements.get('vx-campaign-stat-view')) 
    customElements.define('vx-campaign-stat-view', TCampaignStatView);
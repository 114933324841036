import {html, css, LitElement, unsafeCSS} from 'lit'
import { classMap } from 'lit/directives/class-map.js'
import {TBase} from '../base'


export class TMenuItem extends TBase 
{
    static styles = [ 
        TBase.styles, 
        css`
            a:not([active]):hover {
                --bs-btn-color: rgb(230, 230, 230);
                --bs-btn-bg: #f8f9fa;
                --bs-btn-border-color: #f8f9fa;
                --bs-btn-hover-color: #000;
                --bs-btn-hover-bg: #d3d4d5;
                --bs-btn-hover-border-color: #c6c7c8;
                --bs-btn-focus-shadow-rgb: 211, 212, 213;
                --bs-btn-active-color: #000;
                --bs-btn-active-bg: #c6c7c8;
                --bs-btn-active-border-color: #babbbc;
                --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                --bs-btn-disabled-color: #000;
                --bs-btn-disabled-bg: #f8f9fa;
                --bs-btn-disabled-border-color: #f8f9fa;
            }
        ` ]

    static properties = {
        ...TBase.properties,
        href : {type: String},
        active:{type: Boolean},
        icon : {type: String, attribute: 'data-vx-icon'}
    };

    constructor() {
        super()
        this.color = "dark"
    }

    render() {
        console.log('icon', this.icon)
        let classes = {btn: true, 'btn-sm': true, 'text-start': true, 'btn-primary': this.active}
        return html`
            ${super.render()}
            <div class="d-grid gap-2">
                <a 
                    href="${this.href}" 
                    class="${classMap(classes)}" 
                    @click="${this._navigate}" 
                    style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: 1rem;"
                    ?active="${this.active}"
                >
                    <vx-icon name=${this.icon} size="24" class="me-2"></vx-icon>
                    <slot></slot>
                </a>
            </div>

        `
    }
//                    ${this.icon? html`<i class="icon icon-${this.icon} icon-right-padding"></i>` : ''}}

    // --- custom functions 
    _navigate(e) {
        e.preventDefault()
        this.dispatchEvent(new CustomEvent('navigate', {bubbles: true, cancelable: true, composed: true, detail: {sender: this, url: this.href}}))
    }
}

if (!customElements.get('vx-menu-item')) 
    customElements.define('vx-menu-item', TMenuItem);    
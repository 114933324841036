import {Chart} from 'chart.js/auto'
import {css, html} from 'lit-element'
import {TBase} from '../base'

export class TChart extends TBase 
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properties,
        chart    : {attribute: false},
        title    : {type: String, attribute: 'data-vx-title'},
        type     : {type: String, attribute: 'data-vx-type'},
        options  : {type: Object, attribute: 'data-vx-options'},
        data     : {type: Object, attribute: 'data-vx-data'},
        labels   : {type: Array , attribute: 'data-vx-labels'},
        axis     : {type: Object, attribute: 'data-vx-axis'}
    }

    constructor()
    {
        super()
        this.type = this.type || 'pie'
        this.title = 'Chart'
        this.options = {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: false,
                    text: this.title
                }
            }
        }
    }

    compile() {
        /**
         * 
         * @param {object} data 
         * @param {object} axis
         * @param {array} labels
         * example:
         *      data = { paul: 2, jack: 3, Mike: 12 }
         *      data = (ages: {paul: 2, jack: 3}, grades: {paul: 3, mike: 4})
         *      axis = { title: "data set 1", data } 
         *      axis = { title: "data set 1", color: "red", labels: [{ data: 'paul', title: 'PAUL'}, ...]}
         *      labels = [{ data: 'paul', title: 'PAUL', color: 'red' }, ...]
         *      if no labels :
         *          axis.labels = [{data: 'paul', title: 'paul'}]
         *      if no color is set to labels, take axis.color. If not set get a random color
         */
        const compileDataSet = (data, axis, labels) => {
            console.log(this.name, 'compileDataSet', data, axis, labels)
            const dataset = {}
            if(data) {
                dataset.label = axis.title
                dataset.data  = labels.map( label => data[label.data])
                dataset.borderWidth = 1
                dataset.backgroundColor = axis.color
            }
            return dataset
        } 

        const getColor = (index) => {
            const colors = ['green', 'orange', 'red', 'brown', 'black', 'blue', 'pink', 'purple', 'yellow', 'white', 'gray', 'cyan', 'magenta']
            return colors[index%colors.length]
        }

        const getLabels = () => {
            var labels = self.labels || Object.keys(this.data) || [{}]
            console.log('CHART', 'labels', labels)
            labels = labels.map ( (label, index) => {
                if(typeof label === 'string')   label = {data: label}
                label.title = label.title || label.data 
                label.color = label.color || getColor(index)
                return label
            })
            console.log('this.labels', labels)
            console.log('getLabels', labels)
            return labels
        }

        const getData = (axis) => {
            const data = (axis.data) ? this.data[axis.data] : this.data
            console.log('data of', axis, data)
            return data
        }

        const getAxis = (colors) => {
            console.log('getAxis : this.axis', this.axis)
            var axis = this.axis || [{}]
            axis = Array.isArray(axis)? axis : [axis]
            console.log('axis', axis)
            axis.forEach ( axe => {
                axe.title = axe.title || axe.label
                axe.color = axe.color || colors
            } )
            console.log('getAxis', axis)
            return axis
        }

        var self = this
        const labels = getLabels()
        console.log('labels', labels)
        const axis = getAxis(labels.map( label => label.color ))
        const datasets = axis.map( (axe, i) => compileDataSet(getData(axe), axe, labels) )
        console.log('datasets', datasets)
        const chartData = {labels: labels.map( label => label.title ), datasets} 
        return chartData
    }

    updateChart()
    {
        try {
            console.log('updateChart', this.type, this.data, this.options)
            const type    = this.type 
            const data    = this.compile()        
            const options = this.options 
            const config  = {type, data, options} 

            if(!this.chart) 
            {
                console.log('creating chart', config)
                const ctx = this.shadowRoot.querySelector('canvas').getContext('2d')
                console.log('ctx', ctx)
                this.chart = new Chart(ctx, config)
            }
            else 
            {
                console.log('updating chart')
                this.chart.type = this.type
                this.chart.data = data 
                this.chart.options = options 
                this.chart.update()
            }
        }
        catch(e) { console.error('chart error', e) }
    }

    updated(changedProperties) {
        console.log('Table rendering done.. Loading DataTable...', changedProperties)
        console.log('chart DOM updated...')
        console.log('data', this.data)
        if(this.data)
            this.updateChart()
    }

    render() 
    {
        console.log('initializing chart..', this.labels)
        if (!this.data)     return html`...`
        return html`
            <style>
                .chart-size{
                    position: relative;
                }
                canvas{
                    width:400px;
                    height:400px;
                }
            </style>
            <div>
                <canvas></canvas>
            </div>
        `;
    }
}

if (!customElements.get('vx-chart')) 
    customElements.define('vx-chart', TChart)


/*
data = {paul: 1, jack: 2}
data = [{paul: 1, jack: 2} {michel: 1, joe: 2}]
sets = {title: 'name', labels: [{data: 'paul', label: 'PAUL'}, {data: 'jack', label: 'JACK']} 
sets = [{title: 'name', labels: [{data: 'paul', label: 'PAUL'}, {data: 'jack', label: 'JACK']}, {title: 'name', labels: [{data: 'paul', label: 'PAUL'}, {data: 'jack', label: 'JACK']}] 

*/
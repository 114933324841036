import {html, css, LitElement, unsafeCSS} from 'lit'
import {TBase} from './base'


export class TLayout extends TBase 
{
    static styles = css`
        :host {
            color: var(--default-text-color);
            font-family: var(--default-font-family);
            font-size: var(--default-font-size)

            --vx-content-padding: 2rem;
            --vx-sidebar-width: 264px;
            --vx-sidebar-padding: 0rem;
            --vx-sidebar-transition: all 0.35s ease-in-out;
            --vx-sidebar-background-color: rgb(242, 242, 242);
            --vx-btn-padding-x: 0.75rem;
            --vx-btn-padding-y: 0.375rem;
            --vx-btn-font-family: ;
            --vx-btn-font-size: 1rem;
            --vx-btn-font-weight: 400;
            --vx-btn-line-height: 1.5;
            --vx-btn-color: white;
            --vx-btn-bg: transparent;
            --vx-btn-border-width: 0;
            --vx-btn-border-color: transparent;
            --vx-btn-border-radius: 6px;
            --vx-btn-hover-border-color: transparent;
            --vx-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
            --vx-btn-disabled-opacity: 0.65;
            --vx-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, .5);
            --vx-light-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
            --vx-dark-toggler-icon-bg : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
        }

        ::slotted(*) {
            width: 100%;
        }


        main {
            align-items: stretch;
            display: flex;
            width: 100%;
            max-height: 100vh;
            overflow: scroll;
        }

        aside {
            max-width: var(--vx-sidebar-width);
            min-width: var(--vx-sidebar-width);
            transition: var(--vx-sidebar-transition);
            background-color: var(--vx-sidebar-background-color);
            padding: var(--vx-sidebar-padding);
            z-index: 1111;
            margin-left: calc((-1 * (var(--vx-sidebar-width)) - (2 * (var(--vx-sidebar-padding)))));
        }

        .toggle {
            margin-left: 0;
        }
                
        .page {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
            width: 100%;
            overflow: hidden;
            transition: var(--vx-sidebar-transition);
        }
                    
        .content {
            flex: 1;
            max-width: 100vw;
            width: 100vw;
            min-height: 100vh;
            padding-left: var(--vx-content-padding);
            overflow: auto
        }

        button {
            display: inline-block;
            padding: var(--vx-btn-padding-y) var(--vx-btn-padding-x);
            font-family: var(--vx-btn-font-family);
            font-size: var(--vx-btn-font-size);
            font-weight: var(--vx-btn-font-weight);
            line-height: var(--vx-btn-line-height);
            color: var(--vx-btn-color);
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            border: var(--vx-btn-border-width) solid var(--vx-btn-border-color);
            border-radius: var(--vx-btn-border-radius);
            background-color: var(--vx-btn-bg);
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        }    

        .toggler-icon {
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
            vertical-align: middle;
            background-image: var(--vx-light-toggler-icon-bg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
        }

        /* Responsive */

        @media (min-width:768px) {
            .content {
                width: auto;
            }

            .navbar {
                display: none !important;
            }

            .toggle {
                margin-left: calc((-1 * (var(--vx-sidebar-width)) - (2 * (var(--vx-sidebar-padding)))));
            }

            aside {
                margin-left: 0;
            }

        }
    `;

    static properties = {
    };

    constructor() {
        super()
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('navigate', this.handleCustomEvent);
    }
    
      disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('navigate', this.handleCustomEvent);
    }
    
    handleCustomEvent(event) {
        // Handle the custom event here
        console.log('layout got navigate event', event);
    }

    toggleSidebar(e) {
        let sidebar = this.renderRoot.querySelector('aside')
        if(sidebar)     sidebar.classList.toggle("toggle")
    }

    render() {
        return html`
        <main>
            <!-- Sidebar -->
            <aside><slot name="sidebar"></slot></aside>
            <div class="page">
                <nav class="navbar">
                    <button class="btn-toggle" type="button" @click="${this.toggleSidebar}">
                        <span class="toggler-icon"></span>
                    </button>
                </nav>
                <div class="content">
                    <slot name="content"></slot>                    
                </div>
            </div>
        </main>`;
    }
}

if (!customElements.get('vx-layout')) 
    customElements.define('vx-layout', TLayout);
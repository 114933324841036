import {html, css} from 'lit'
import {TBase} from '../../../wcl/base'

export class TUserListView extends TBase 
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properies,
        users : { type: String, attribute: 'data-vx-users' }
    }

    constructor() {
        super()
    }

    render() {
        console.log('rendering user-list', this.users)
        const columns = [{data: 'name', title: 'Nom'}, {data: 'privileges', title: 'privilèges'}, {data: 'username', title: `Nom d'utilisateur`}, {data: 'status', title: 'Statut'}]
        const header  = {text: 'Liste des utilisateurs', buttons: [{action: 'create', icon: 'plus-square'}]} 
        return html`
            ${super.render()}
            <div class="container-fluid pe-5 mt-5">
                    <div class="row"><vx-card>USERS</vx-card></div>
                    <div class="row pt-3">
                        <vx-card 
                            name="user"
                            @action=${this.relay}
                            data-vx-header=${JSON.stringify(header)}>
                            <vx-datatable
                                name="user" 
                                data-vx-datasrc="${this.users}"
                                data-vx-cols=${JSON.stringify(columns)}
                                @action="${this.relay}"
                                striped hover>
                            </vx-datatable>
                        </vx-card>
                    </div>
                </div>
        `
    }
}

if (!customElements.get('vx-user-list-view')) 
    customElements.define('vx-user-list-view', TUserListView);



import {html, css} from 'lit'
import {TBase} from '../../../wcl/base'

export class TDidListView extends TBase 
{
    static styles = [ TBase.styles, css`` ]

    static properties = {
        ...TBase.properies,
        dids : { type: String, attribute: 'data-vx-dids' }
    }

    constructor() {
        super()
    }

    render() {
        console.log('rendering did-list', this.dids)
        const columns = [{ data: 'name', title: 'Nom' }, { data: 'number', title: 'Numéro' }, { data: 'description', title: 'Description' }]
        const header  = {text: 'Liste des SDA', buttons: [{action: 'create', icon: 'plus-square'}]} 
        return html`
            ${super.render()}
            <div class="container-fluid pe-5 mt-5">
                    <div class="row"><vx-card>VSQV / SDA</vx-card></div>
                    <div class="row pt-3">
                        <vx-card 
                            name="did"
                            @action=${this.relay}
                            data-vx-header=${JSON.stringify(header)}>
                            <vx-datatable
                                name="did" 
                                data-vx-datasrc="${this.dids}"
                                data-vx-cols=${JSON.stringify(columns)}
                                @action="${this.relay}"
                                striped hover>
                            </vx-datatable>
                        </vx-card>
                    </div>
                </div>
        `
    }
}

if (!customElements.get('vx-did-list-view')) 
    customElements.define('vx-did-list-view', TDidListView);


